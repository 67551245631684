<template>
  <section class="create_comments">
    <v-form>
      <v-container>
        <h1>Create Comment</h1>
        <v-row>
          <v-col
              cols="12"
              sm="6"
          >
            <v-text-field
                v-model="form_data.name"
                label="Name"
                outlined
            ></v-text-field>
          </v-col>

          <v-col
              cols="12"
              sm="6"
          >
            <v-text-field
                v-model="form_data.since"
                label="Since"
                outlined
            ></v-text-field>
          </v-col>
          <v-col
              cols="12"
              sm="6"
          >
            <v-file-input
                v-model="files"
                color="deep-purple accent-4"
                counter
                label="File input"
                placeholder="Select your files"
                prepend-icon=""
                outlined
                :show-size="1000"
            >
              <template v-slot:selection="{ index, text }">
                <v-chip
                    v-if="index < 2"
                    color="deep-purple accent-4"
                    dark
                    label
                    small
                >
                  {{ text }}
                </v-chip>
              </template>
            </v-file-input>
          </v-col>

          <v-col
              cols="12"
              sm="6"
          >
            <v-text-field
                v-model="form_data.comment"
                label="Comment"
                outlined
            ></v-text-field>
          </v-col>

          <v-btn
              :loading="loading_form"
              :disabled="loading_form"
              color="blue-grey"
              outlined
              class="ma-2 white--text"
              @click="create_comment"
          >
            Add
            <v-icon
                right
                dark
            >
              mdi-cloud-upload
            </v-icon>
          </v-btn>

        </v-row>
      </v-container>
    </v-form>
  </section>
</template>

<script>
import {add_new_comment} from "@/api/admin";

export default {
  data: () => ({
    loading_form: false,
    files: [],
    form_data: {
      name: '',
      since: '',
      image: null,
      comment: null,
    }
  }),
  methods: {
    create_comment() {
      this.loading_form = true;
      let form_data = new FormData();
      form_data.append('name', this.form_data.name)
      form_data.append('since', this.form_data.since)
      form_data.append('image', this.files)
      form_data.append('comment', this.form_data.comment)


      add_new_comment(form_data).then(res => {
       alert('comment added')
        this.loading_form = false;
      }).catch(() => {});
    }
  }
}
</script>